import React, { useState, useRef } from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import CustomAxios from "../../utils/axios"

import { Buttons } from "../buttons"

const FormStand = ({ data, btnText, btnClass }) => {

  function getSubstring(str, start, end) {
    var char1 = str.indexOf(start) + 8;
    var char2 = str.lastIndexOf(end);
    return str.substring(char1, char2);
  }

  let nameCar = getSubstring(data.title, '<BR/><B>', '</B>');

  const [response, setResponse] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  const name = useRef(null);
  const phone = useRef(null);
  const email = useRef(null);

  var checker = [];
  function checkValues() {
    checker = [];
    let checkerTemp = [];

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    name.current.style.borderBottom = "1px solid #fff";
    email.current.style.borderBottom = "1px solid #fff";
    phone.current.style.borderBottom = "1px solid #fff";

    if (!name.current.value) {
      checkerTemp.push("nome");
      name.current.style.borderBottom = "1px solid red";
    }
    if (!email.current.value) {
      checkerTemp.push("email");
      email.current.style.borderBottom = "1px solid red";
    }
    if (!email.current.value.match(validRegex)) {
      checkerTemp.push("email inválido");
      email.current.style.borderBottom = "1px solid red";
    }
    if (!phone.current.value) {
      checkerTemp.push("telefone");
      phone.current.style.borderBottom = "1px solid red";
    }
    checker = checkerTemp;
  }

  function doSubmit() {
    checkValues();
    if (checker.length < 1) {

      var formData = new FormData()
      formData.append("name", name.current.value)
      formData.append("standCar", nameCar)
      formData.append("email", email.current.value)
      formData.append("phone", phone.current.value)
      formData.append("stand", true)

      CustomAxios("https://hcsperformance.pt/form-contact.php", formData, "ENVIADO").then((res) => {
        if (res === "ENVIADO") {
          success("OBRIGADO PELA SUA MENSAGEM")
        }
        else {
          setResponse("Faltam os seguintes campos: " + checker + ".");
        }
      });
      //CustomAxios("/form-contact.php", formData, "OBRIGADO PELA SUA MENSAGEM").then((res) => success(res));
      //CustomAxios("https://invisual.pt/teste-form/form-contact.php", formData, "ENVIADO").then((res) => {
    }
    else {
      setResponse("Faltam os seguintes campos: " + checker + ".");
    }
  }

  const success = (res) => {
    setResponse(res)
    setFormSuccess(true)

    if (typeof window !== "undefined") {
      window.gtag("event", 'form_stand', {
        form: "Formulário de Stand"
      })
    }
  }

  return (
    <StyledForm id="form">
      <h3 className={font.oEL + " uppercase"} dangerouslySetInnerHTML={{ __html: data.title }}>
      </h3>

      {response === "OBRIGADO PELA SUA MENSAGEM" ?
        <div className={formSuccess ? "form-grid section-response form-success" : "form-grid section-response"}>
          <h4 className={font.oR + " response"}>{response}</h4>
        </div>
        :
        <form className="form-grid" netlify>
          <div className="form-section">
            <p className={font.oL + " big formTitle "}>{data.name}</p>
            <input type="text" ref={name} className={font.oL} />
          </div>

          <div className="form-section mt-5">
            <p className={font.oL + " big formTitle"}>{data.phone}</p>
            <input type="text" ref={phone} className={font.oL} />
          </div>

          <div className="form-section mt-5">
            <p className={font.oL + " big formTitle"}>{data.email}</p>
            <input type="text" ref={email} className={font.oL} id="email" />
          </div>

          <div className="form-section mt-5">
            {response !== "OBRIGADO PELA SUA MENSAGEM" && <h4 className={font.oR + " response"}>{response}</h4>}
          </div>

          <div className="form-section">
            <div className={font.oEL + " form-section div-btn "} >
              <Buttons text={btnText} actionFunction={doSubmit}
                btnClass={btnClass} />
            </div>
          </div>
        </form>
      }
    </StyledForm>
  )
}


export default FormStand

const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: all 650ms ease-out;

  .section-response{
    min-height: 25vh;
    align-items: center;
    display: grid;
  }
  .uppercase{
    text-transform: uppercase;
  }
  .div-btn{
    width: 12vw;
    margin: 0;
  }
  .form-grid{
    display: grid;
    width: 100%;
    .form-section{
      margin-top: 1vh;
      text-align: left;
      input{
        color: #fff;
        width: 100%;
        background-color: transparent;
        outline: none;
        border: none;
        border-bottom: 2px solid #fff;
      }
      .form-send-btn{
        align-self: center;
        display: block;
        margin-left: 0;
        height: 2.5em;
        img{
          height: 100%;
        }
      }
    }
  }
  .response{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .mt-5{
    margin-top: 5vh !important;
  }

  .title{
    text-align: left !important;
  }
`